import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useGetStaticImageUrl } from '@/hooks/useGetStaticImageUrl/useGetStaticImageUrl';
import { renderClasses } from '@/utils/renderClassses';
import { ImageFormat, ImageType } from '@/types/types';
import type { IconLookup } from '@fortawesome/fontawesome-common-types';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface Icon extends Partial<FontAwesomeIconProps> {
  icon?: IconLookup
  name?: 'chart-user-custom-tasks' | 'chart-user-custom-days' | 'chart-user-custom-tasks-and-days' | 'chart-user-custom-projects' | 'magnifying-glass-dollar-custom-estimates' | 'magnifying-glass-dollar-custom-costs' | 'magnifying-glass-dollar-custom-margin' | 'magnifying-glass-dollar-custom-revenue' | 'magnifying-glass-custom-question'
  className?: string;
  isCustom?: boolean;
}

export const Icon: React.FC<Icon> = (props: Icon) => {
  const {
    icon,
    name,
    className = '',
    isCustom = false,
    ...iconProps
  } = props;

  const staticImageUrlHelper = useGetStaticImageUrl();

  if (!icon && !name) {
    return null;
  }

  const extendedClassName = `${className} dark:to-white-image-filter`;

  const { width: widthProp, height: heightProp } = props;

  if (name === 'chart-user-custom-tasks') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'chartUserCustomTasks', ImageFormat.SVG)} />
    );
  }
  if (name === 'chart-user-custom-days') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'chartUserCustomDays', ImageFormat.SVG)} />
    );
  }
  if (name === 'chart-user-custom-tasks-and-days') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'chartUserCustomTasksAndDays', ImageFormat.SVG)} />
    );
  }
  if (name === 'chart-user-custom-projects') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'chartUserCustomProjects', ImageFormat.SVG)} />
    );
  }
  if (name === 'magnifying-glass-dollar-custom-estimates') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'magnifyingGlassDollarCustomEstimates', ImageFormat.SVG)} />
    );
  }
  if (name === 'magnifying-glass-dollar-custom-revenue') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'magnifyingGlassDollarCustomRevenue', ImageFormat.SVG)} />
    );
  }
  if (name === 'magnifying-glass-dollar-custom-costs') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'magnifyingGlassDollarCustomCosts', ImageFormat.SVG)} />
    );
  }
  if (name === 'magnifying-glass-dollar-custom-margin') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'magnifyingGlassDollarCustomMargin', ImageFormat.SVG)} />
    );
  }
  if (name === 'magnifying-glass-custom-question') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'magnifyingGlassCustomQuestion', ImageFormat.SVG)} />
    );
  }
  if (name === 'chart-mixed-custom') {
    return (
      <img className={extendedClassName} src={staticImageUrlHelper.getImageUrl(ImageType.CUSTOM_ICONS, 'chartMixedCustom', ImageFormat.SVG)} />
    );
  }

  if (isCustom) {
    return (
      <i
        className={renderClasses({
          [className]: true,
          fak: true,
          [name]: true,
        })}
        style={{ width: widthProp, height: heightProp }}
      />
    );
  }

  return <FontAwesomeIcon icon={icon} className={className} {...iconProps} />;
};
