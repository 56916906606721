import { createSelector } from '@reduxjs/toolkit';
import { domain } from '@/redux/models/SettingModel/index';
import type { FeatureFlag,
  GroupSetting,
  Permission,
  RuntimeSetting,
  SettingModelState,
  SettingModule } from '@/redux/models/SettingModel/types';
import { Theme as SettingTheme,
  CurrencySettingEnum,
  SettingsModelStateFields,
  UserSetting,
} from '@/redux/models/SettingModel/types';
import { Theme } from '@/contexts/interfaces';
import { config } from '@/config';
import { getDefaultValue } from './utils';

export const selectDomain = (state) => state[domain] as SettingModelState;
export const selectSettings = createSelector(
  selectDomain,
  (_, args: { type: SettingsModelStateFields }) => args.type,
  (domain: any, field) => domain.get(field) || {},
);

export const selectSyncLoading = createSelector(
  selectDomain,
  (domain) => domain.get(SettingsModelStateFields.SYNC_INTEGRATIONS_LOADING) || false,
);

export const selectSetting = createSelector(
  selectSettings,
  (_, args: { name: GroupSetting | UserSetting | RuntimeSetting | Permission | FeatureFlag }) => args.name,
  (_, args: { type }) => args.type,
  (domain: any, name, type: SettingsModelStateFields) => {
    const setting = domain.get(name);

    if (!setting) {
      return getDefaultValue(type, name);
    }

    return setting;
  },
);

export const selectSettingModule = createSelector(
  selectDomain,
  (_, args: { name: SettingModule }) => args.name,
  (domain: any, name) => {
    const module = domain.getIn([SettingsModelStateFields.MODULES, name]);

    if (!module) {
      return getDefaultValue(SettingsModelStateFields.MODULES, name);
    }

    return module;
  },
);

export const selectCurrency = createSelector(selectDomain, (settings) => {
  const type = SettingsModelStateFields.CURRENCY_SETTING;
  const setting = settings.getIn([type, CurrencySettingEnum.CURRENCY]);
  return setting;
});

export const selectTheme = createSelector(selectDomain, (settings) => {
  const type = SettingsModelStateFields.USER_SETTING;
  const name = UserSetting.THEME;
  const configTheme: Theme = config.userSetting?.theme === SettingTheme.DARKMODE ? Theme.DARK : Theme.MODERN;

  return settings.getIn([type, name]) || configTheme;
});

